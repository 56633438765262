<template>
  <div>
    <section class="section is-medium">
      <div class="container">
        <h1 class="title has-text-centered">Contact Us</h1>
        <h4 class="subtitle has-text-centered">We'll respond within 24 hours</h4>

        <div class="columns is-centered">
          <div class="column is-6">
            <div class="box">
              <Field v-bind.sync="name" :label="'Name'" :func="'name'" />

              <Field v-bind.sync="email" :label="'Email'" :func="'email'" />

              <div class="field">
                <label class="label is-small" style="font-weight: 600">Message</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    placeholder="Message"
                    v-model="message"
                  ></textarea>
                </div>
              </div>

              <button
                class="button is-link"
                :class="{ 'is-loading': processing }"
                @click="submit"
                :disabled="!allValid"
              >
                Send
              </button>
            </div>
            <p class="has-text-centered">
              Get answers faster, use the chat bubble below!
            </p>
          </div>
        </div>
      </div>
    </section>

    <FlashMessage :flash="flash" />
  </div>
</template>

<script>
import { api, field } from '@/modules/util'

export default {
  name: 'Contact',
  props: ['user', 'loading'],
  data() {
    return {
      name: field(),
      email: field(),
      message: '',
      flash: [],
      processing: false,
    }
  },
  computed: {
    allValid() {
      return this.name.valid && this.email.valid && this.message.length > 5
    },
  },
  methods: {
    async submit() {
      try {
        this.processing = true
        await api.post('/contact', {
          name: this.name.value,
          email: this.email.value,
          message: this.message,
        })
        ;(this.message = ''),
          this.flash.push({ msg: 'Received, thank you!', type: 'success' })
        this.processing = false
      } catch (err) {
        this.flash.push({ msg: err, type: 'error' })
        this.processing = false
      }
    },
  },
}
</script>
